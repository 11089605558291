import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Text } from "./styles";
import { ListText,WhiteBorderTitle,WhiteSpace } from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";  // Add this import

export default function Collaboratori({
  about,
  setActiveTab,
  isTabClosing,
  ref,
}) {
  var names = about.collabs.map((collab) => {
    return collab.name;
  });

  const [opacity, setOpacity] = useState(0);
  const mobileScreens = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <Container fluid className="w-md-100 associati-container" ref={ref}>
      {/* <div className="animated-border-container" ref={animatedContainerRef} style={{height: `${animatedBorderContainerHeight}%`}} /> */}
      <Row className="associati-row" style={{ opacity: opacity }}>
        <Text
          className="mobile-tab-title associati-title"
          onClick={() => setActiveTab(undefined)}
        >
          Collaboratori
          <WhiteBorderTitle />
        </Text>
        
        <div className="hack col-mattia"> </div>
        {mobileScreens && <WhiteSpace />}
        <div className="col-about-tab-mobile">
          {names.map((name, index) => {
            if (name !== " ") {
              return (
                <ListText
                  style={{
                    lineHeight: "22px",
                    // paddingBottom: index === names.length - 1 ? 0 : 12,
                  }}
                  key={"name " + name}
                >
                  {name}
                </ListText>
              );
            }
            // eslint-disable-next-line
            return;
          })}
        </div>
      </Row>
    </Container>
  );
}
