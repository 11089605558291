import styled from "styled-components";

export const Text = styled.p`
  // font-size: 19px;
  font-family: "Libre Franklin", sans-serif;
  line-height: 1.5rem;
  margin: 0px;
  color: #776e62;
`;
export const Title = styled.h2`
  // font-size: 19px;
  margin-bottom: 0;
`;
export const Fade = styled.div`
  opacity: ${(props) => (props.in ? 1 : 0)};
  transition: opacity 400ms;
  position: absolute;
  @media screen and (max-width: 500px) {
    position: relative;
    display: ${(props) => (props.in ? "block" : "none")};
  }
  @media screen and (max-width: 750px) {
    opacity: 1 !important;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgb(240, 240, 240);
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
