import React, { useState, useEffect } from "react";
// MATERIAL UI
import { Close } from "@material-ui/icons";
// BOOTSTRAP
import { Container, Row } from "react-bootstrap";
// STYLED COMPONENTS
import { Button, Left } from "./styles";

function MobileTabMenu({ tabs, activeTab, setActiveTab, close, active }) {
  // STATE HOOKS
  const [displayFilter, enableFilter] = useState(false);

  var clientY = 0;

  useEffect(() => {
    document.body.classList.add("bg-black");
    document.body.classList.add("fadePage");

    //fade Page
    let el = document.querySelector(".fadePage");
    el.classList.add("fadeIn");

    return () => {
      document.body.classList.remove("bg-black");
      if (isTouchDevice) {
        window.removeEventListener("touchmove", detectTouchMove);
      } else {
        window.removeEventListener("mousewheel", detectMouseWheelDirection);
      }
    };
    // eslint-disable-next-line
  }, []);

  const renderButtons = () => {
    return tabs.map((tab, index) => (
      <div className="filter-wrapper" id={tab} key={tab + "filter"}>
        <Button
          onClick={() => {
            setActiveTab(tab);
            close();
          }}
          isAtive={tab === activeTab}
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflowX: "hidden",
          }}
        >
          {window.innerWidth < 500 ? (
            <>
              <p className="big-nav-button-wrapper">{tab}</p>
            </>
          ) : (
            tab
          )}
        </Button>
      </div>
    ));
  };

  const detectMouseWheelDirection = (e) => {
    let delta = null;
    let direction = false;

    if (!e) {
      // if the event is not provided, we get it from the window object
      e = window.event;
    }

    // wheelDelta IS DEPRACATED

    if (e.wheelDelta) {
      // will work in most cases
      delta = e.wheelDelta / 60;
    } else if (e.detail) {
      // fallback for Firefox OBS: FALLBACK NOT WORKING, FEATURE NEVER ADDED BY FIREFOX.
      delta = -e.detail / 2;
    }

    // This detects
    if (delta !== null) {
      direction = delta > 0 ? "up" : "down";

      if (direction === "down") {
        enableFilter(true);
      }
    }

    return direction;
  };

  const detectTouchMove = (e) => {
    if (e.changedTouches[0].clientY - clientY > 0) {
      enableFilter(true);
    } else {
      enableFilter(true);
    }
    clientY = e.changedTouches[0].clientY;
  };

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  return (
    <Container
      fluid
      style={{
        display: active ? "block" : "none",
        overflowX: "hidden",
        position: "relative",
      }}
    >
      <Close
        style={{
          position: "fixed",
          color: "#5a5a5a",
          top: "20px",
          left: "16px",
          width: "18px",
          cursor: "pointer",
          zIndex: 333,
        }}
        onClick={() => close()}
      />

      <Row style={{ zIndex: 333 }} className={"sticky-navbar"}>
        {/* PROJECT NAMES LIST */}
        <div
          className={displayFilter ? "marginTopButtons" : "noMarginTopButtons"}
        >
          <Left
            id="project-buttons-container"
            style={{ zIndex: "333", position: "relative" }}
            className="p-filter-remove-black-line blank-bottom-space fix-scrollbar"
          >
            <div className="filter-wrapper" id="">
              <Button
                style={{
                  opacity: 0,
                }}
              >
                {window.innerWidth < 500 ? (
                  <>
                    <p className="big-nav-button-wrapper">a</p>
                  </>
                ) : (
                  "a"
                )}
              </Button>
            </div>
            {renderButtons()}
            <div className="space-bottom"></div>
          </Left>
        </div>
      </Row>
    </Container>
  );
}

export default MobileTabMenu;
