import React, { useEffect, useRef, useState } from "react";
import { Text, ContactText } from "./styles";
import WhiteBorderTop from "../WhiteBorderTop";
import "./style.css";

const Contacts = () => {
  return (
    <div className="contacts-container">
      <ContactText style={{ color: "#848484" }}>PCArchitetti sa</ContactText>
      <ContactText style={{ color: "#848484", textDecoration: "none" }}>
        <a
          href="mailto:mail@pcarchitetti.ch"
          target="_blank"
          style={{ borderBottom: "none" }}
          rel="noopener noreferrer"
        >
          mail@pcarchitetti.ch
        </a>
      </ContactText>
      <ContactText style={{ color: "#848484" }}>
        <a
          href="tel:+41916056861"
          target="_blank"
          style={{ borderBottom: "none" }}
          rel="noopener noreferrer"
        >
          +41 91 605 68 61
        </a>
      </ContactText>
      <ContactText style={{ color: "#848484" }}>
        Corso Pestalozzi 4 6900 Lugano, Svizzera
      </ContactText>
    </div>
  );
};

export default function Tabs({ tabs, setActiveTab, activeTab, isMenuClosing }) {
  const [animatedBorderContainerHeight, setAnimatedBorderContainerHeight] =
    useState(0);
  const animatedContainerRef = useRef(null);

  useEffect(() => {
    if (animatedContainerRef.current) {
      if (isMenuClosing) {
        setAnimatedBorderContainerHeight("0%");
      } else {
        setTimeout(() => {
          setAnimatedBorderContainerHeight("calc(100vh - 50px)");
        }, 300);
      }
    }
  }, [animatedContainerRef.current, isMenuClosing]);

  return (
    <>
      <div className="hide-mobile menu-tabs">
        <div
          className="animated-border-container"
          ref={animatedContainerRef}
          style={{
            height: `${animatedBorderContainerHeight}`,
            borderColor: "black",
            transition: "height 0.5s ease-in-out",
            zIndex: 1
          }}
        />
        {!isMenuClosing && (
          <div style={{
            animation: `fadeIn 1s ease-in-out forwards`,
            animationDelay: `0.5s`,
            opacity: 0,
            position: 'relative',
            zIndex: 2
          }}>
            {tabs.map((tab, idx) => (
              <div
                onClick={() => {
                  setActiveTab(tab);
                  window.scroll(0, 0);
                }}
                style={{ 
                  zIndex: 2,
                  position: "relative",
                  cursor: 'pointer'
                }}
                className={`bold-hover ${activeTab === tab ? "bold-active" : ""}`}
                key={tab}
              >
                <Text
                  className={`bold-hover ${activeTab === tab ? "bold-active" : ""}`}
                >
                  {tab}
                </Text>
              </div>
            ))}
            <Contacts />
          </div>
        )}
      </div>

      <div
        style={{ position: "relative" }}
        className="show-mobile about-menu-tile-margin"
        id="tab-menu-mobile"
      >
        <div className="top-white-border" />
        {tabs.map((tab, idx) => (
          <>
            <Text
              style={{ color: "#000" }}
              key={tab}
              onClick={() => {
                setActiveTab(tab);
              }}
              className="down-arrow-mobile"
            >
              {tab}
              <WhiteBorderTop />
            </Text>
          </>
        ))}
        <Contacts />
      </div>
    </>
  );
}
