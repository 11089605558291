import React, { useEffect } from "react";

import { Main } from "./styles";
import { useDispatch } from "react-redux";
import { trigger } from "../../storage/redux/about";
import Menu from "../../components/Menu";

function Home() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trigger());
  }, [dispatch]);

  return (
    <Main>
      <Menu color="#ffffff" sourcePage="home" />
    </Main>
  );
}

export default Home;
