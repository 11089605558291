import React from "react";

export default function ({ option = 1 }) {
  return (
    <section className="white-border-top">
      <article className={`white-border-top-left${option}`}></article>
      <article className={`white-border-top-right${option}`}></article>
    </section>
  );
}
