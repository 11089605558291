import React from "react";
import { Container, Row } from "react-bootstrap";
import HtmlRender from "../../components/HtmlRender";
import { Fade } from "@material-ui/core";
export default function Biografia({
  bioAndDescriptions,
  about,
  mobileScreens,
  pageLoadStatus,
  myRef,
  fade,
}) {
  return (
    <Container
      style={{ flexDirection: "column" }}
      fluid
      className=" w-md-100 pb-4"
    >
      <Container
        id={"tabs-wrapper"}
        ref={myRef}
        fluid
        className="w-md-100 pb-4 test  show-mobile"
        style={{ overflowX: "hidden" }}
      >
        {about.contact !== undefined ? (
          <>
            {about.contact.image === null ||
            about.contact.image === undefined ? null : mobileScreens ? (
              // Fade animation on scroll strictly for mobiles
              <Fade in={!fade} timeout={1000}>
                <img
                  className="col-mattia about-image"
                  style={{ marginTop: 10 }}
                  src={about.contact.image.url}
                  alt="contact img"
                />
              </Fade>
            ) : (
              // Fade animation on page load strictly for desktop
              <Fade in={pageLoadStatus} timeout={1000}>
                <img
                  className="col-mattia about-image"
                  style={{ marginTop: 10 }}
                  src={about.contact.image.url}
                  alt="contact img"
                />
              </Fade>
            )}
          </>
        ) : null}
      </Container>
      <Row style={{ marginBottom: "1rem" }}>
        <div className="hack col-mattia"> </div>
        <div
          style={{
            "& p": {
              lineHeight: "22px",
            },
          }}
          className="col-about-tab normalize-bios-text"
        >
          <HtmlRender>{bioAndDescriptions?.Description}</HtmlRender>
          <br />
        </div>
      </Row>
    </Container>
  );
}
