import React from 'react';
import { BlackOverlayWrapper } from './styles';
import PCAWhiteLogo from '../../assets/logo/PCA_white.svg';
import PCAWhiteLogoLong from '../../assets/logo/PCA_m_white_long_new_mattia.svg';
function BlackOverlay({ isActive }) {
    const desktopStyles = {
      width: "177px",
      height: "32px",
      cursor: "pointer",
      opacity: 1,
      mixBlendMode: 'inverted'
    };
  
    const mobileStyles = {
      width: "179px",  // adjust these values
      height: "47px",  // for your mobile logo
      cursor: "pointer",
      opacity: 1,
      mixBlendMode: 'inverted'
    };
  
    const isMobile = window.innerWidth <= 750;
  
    return (
      <BlackOverlayWrapper className={isActive ? '' : 'fadeOut'}>
        <div className="logo-container">
          <picture>
            <source 
              media="(max-width: 750px)" 
              srcSet={PCAWhiteLogoLong}
            />
            <img 
              src={isMobile ? PCAWhiteLogoLong : PCAWhiteLogo}
              alt="PCA Logo"
              style={isMobile ? mobileStyles : desktopStyles}
            />
          </picture>
        </div>
      </BlackOverlayWrapper>
    );
  }

export default BlackOverlay; 