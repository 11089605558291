import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from '../pages/Home/index';
import Work from '../pages/Work/index';
import Filter from '../pages/Filter/index';
import About from '../pages/About/index';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Redirect to="/work/gaggiorisanamentorustico" />
        </Route>
        <Route path="/work/:codename" component={Work} />
        <Route path="/about" component={About} />
        <Route path="/filter" component={Filter} />
      </Switch>
    </BrowserRouter>
  );
}
