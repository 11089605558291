import styled from "styled-components";

export const LogoContainer = styled.div`
  position: fixed;
  z-index: 333;
  padding-top: 18.9px;
  padding-left: 50px;
  padding-bottom: 0px;
  max-width: 430px;
  display: flex;
  cursor: pointer;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;

  @media (max-width: 750px) {
    display: none;
  }
`;

export const LogoContainerMobile = styled.div`
  position: fixed;
  z-index: 333;
  padding-left: 45px;
  padding-bottom: 0px;
  max-width: 430px;
  display: flex;
  cursor: pointer;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  margin-top: 20px;
  display: none;

  @media (max-width: 750px) {
    display: flex;
    margin-left: 19.5px !important;
    padding-left: 0px;
  }
`;

export const AboutContainer = styled.div`
  position: fixed;
  z-index: 333;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  overflow-y: hidden;
  background-color: rgb(240, 240, 240) !important;
`;

export const AboutMenu = styled.div`
  margin-left: 140px;
  border-left: 1px solid #000;
  height: 100vh;
  width: 200px;
`;

export const BG = styled.div`
  position: fixed;
  z-index: 332;
  width: 100vw;
  backgorund
  height: 80px;
  @media (max-width:750px) {
    height: 109px;
    background-color: rgb(240, 240, 240) !important;
  }
  @media (max-width: 350px){
    height: 115px;
    background-color: rgb(240, 240, 240) !important;
  }
`;

export const BGabout = styled.div`
  position: fixed;
  z-index: 332;
  width: 100vw;
  height: 80px;

  @media (max-width: 749px) {
    height: 51px;
  }
`;

export const Container2 = styled.div`
  position: fixed;
  left: 50%;
  right: 50%;
  z-index: 333;
  width: max-content;
`;

export const Text = styled.p`
  font-family: "Libre Franklin", sans-serif;
  line-height: 1.3em;
  margin: 0px;
  // font-size: 19px;
  position: relative;
  z-index: 333;
  color: ${(props) => (props.black ? "#000" : "#ffffff")};
  cursor: pointer;
  transition: 600ms;
  text-decoration: none;
  &:hover {
    color: #000;
    transition: 600ms;
  }
`;
export const TextLogo = styled.p`
  font-family: "Libre Franklin", sans-serif;
  line-height: 22px;
  font-weight: 600;
  margin: 0px;
  // font-size: 17px;
  position: relative;
  z-index: 333;
  cursor: pointer;
  transition: 600ms;
  text-decoration: none;
`;

export const AboutTitle = styled.p`
  font-family: "Libre Franklin", sans-serif;
  line-height: 1.3em;
  margin: 0px;
  // font-size: 19px;
  position: relative;
  z-index: 333;
  color: ${(props) => (props.black ? "#000" : "#776e62")};
  cursor: pointer;
  &:hover {
    color: #776e62;
    transition: 400ms;
  }
`;

export const GalleryName = styled.p`
  font-family: Libre Franklin, sans-serif;
  line-height: 1.3em;
  // font-size: 19px;
  position: fixed;
  border-left: 1px solid rgb(0, 0, 0);
  left: 35vw;
  right: 50%;
  width: max-content;
  top: 35px;
  padding-left: 6px;
  padding-top: 3px;
  padding-bottom: 2px;
  z-index: 333;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: all 600ms ease 0s;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
  color: ${(props) => (props.black ? "#000" : "#776e62")};
  &:hover {
    color: #776e62;
    transition: 600ms;
  }

  @media screen and (min-width: 834px) and (max-width: 1023px) {
    left: 47.5vw;
  }

  @media screen and (min-width: 1024px) and (max-width: 1193px) {
    left: 40vw;
  }
`;

export const GalleryNameMobile = styled.p`
  border-left: 1px solid rgb(0, 0, 0);
  padding-left: 20px;
  left: 20px;
  line-height: 26px;
  // font-size: 17px;
  position: fixed;
  z-index: 333;
  width: max-content;
  padding: 10px 0px 14px 6px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  transition: all ease 0.3s;
  margin-bottom: 0px;
  cursor: pointer;
  -webkit-transition: 0.3s ease-out;
  -moz-transition: 0.3s ease-out;
  -o-transition: 0.3s ease-out;
  transition: 0.1s all;
  width: 100%;
  color: ${(props) => (props.black ? "#000" : "#776e62")};
  opacity: ${(props) => props.opacity};
  &:hover {
    color: #776e62;
    transition: 600ms;
  }

  @media (max-width: 750px) {
    top: 59px;
    transform: translateY(0px) !important;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: -50vw;
      width: 200vw;
      height: 100%;
      border: 1px solid rgba(255, 255, 255, 0);
      transition: border-color 1.5s ease-in-out;
    }
  }
`;

export const ButtonClose = styled.button`
  position: fixed;
  z-index: 444;
  top: 16px;
  left: 30px;
  margin-top: 4px;
  background: none;
  color: #5a5a5a;
  font-family: "Libre Franklin", sans-serif;
  // font-size: 1.25rem;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;

  &:hover {
    color: #fff !important;
    transition: 400ms;
  }

  &:focus {
    outline: none !important;
  }

  @media (max-width: 750px) {
    &:hover {
      color: #000000 !important;
    }
  }
`;

export const TextAbout = styled.p`
  // font-size: 19px;
  font-family: "Libre Franklin", sans-serif;
  line-height: 1.5rem;
  margin: 0px;
  color: #776e62;
`;
export const Title = styled.h2`
  // font-size: 19px;
  margin-bottom: 0;
`;
export const Fade = styled.div`
  opacity: ${(props) => (props.in ? 1 : 0)};
  transition: opacity 400ms;
  position: absolute;
  @media screen and (max-width: 500px) {
    position: relative;
    display: ${(props) => (props.in ? "block" : "none")};
  }
`;
