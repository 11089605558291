import React, { useEffect, useRef, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Text } from "./styles";
import arrow from "./../../assets/img/arrow-down.svg";
import external from "./../../assets/img/external.svg";
import { useSelector } from "react-redux";
import WhiteBorderTop from "../WhiteBorderTop";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ListText } from './styles'; 

export default function Associati({
  noAssociati,
  setNoAssociati,
  setActiveTab,
  isTabClosing,
  ref,
  currentAssociati,
  setCurrentAssociati,
}) {
  var { associatis } = useSelector((state) => state.associatis);
  const [opacityAssociatisList, setOpacityAssociatisList] = useState(0);
  const refBio = useRef();
  const mobileScreens = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    setOpacityAssociatisList(1);
  }, []);

  useEffect(() => {
    if (noAssociati) {
      setCurrentAssociati();
      setOpacityAssociatisList(1);
    } else {
      setOpacityAssociatisList(0);
    }
    // eslint-disable-next-line
  }, [noAssociati]);

  const [transitionOpacity, setTransitionOpacity] = useState(0);

  useEffect(() => {
    if (currentAssociati) {
      const el = document.getElementById("associati-tabfade");
      if (el) {
        if (mobileScreens)
          el.scrollTo({
            behavior: "smooth",
            top: 0,
          });
        else {
          el.scrollIntoView({ block: "end", behavior: "smooth" });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentAssociati]);

  useEffect(() => {
    if (refBio.current) {
      setTransitionOpacity(noAssociati ? 0 : 1);
    }
  }, [noAssociati]);

  return (
    <Container
      fluid
      className="w-md-100 associati-container"
      id="associati-container"
      ref={ref}
    >
      <div className="associati-container-border" />
      {/* <div
        className="animated-border-container"
        ref={animatedContainerRef}
        style={{ height: `${animatedBorderContainerHeight}` }}
      /> */}
      <Row className="associati-row">
        <Text
          className="mobile-tab-title associati-title"
          onClick={() => setActiveTab(undefined)}
        >
          Associati
          <WhiteBorderTop />
        </Text>
        <div className="hack col-mattia"> </div>

        <div
          className="associati-names-container"
          style={{ opacity: opacityAssociatisList }}
        >
          <Text
            style={{
              cursor: "pointer",
              padding: 0,
              lineHeight: 22,
            }}
            key="name last"
            className="associati-name bold-hover"
          >
            <WhiteBorderTop />
          </Text>
          {associatis[0].Associati?.map((associati, index) => (
            <Text
              style={{ lineHeight: "22px", cursor: "pointer" }}
              key={"name " + index}
              className="associati-name bold-hover"
              onClick={() => {
                setCurrentAssociati(associati);
                setNoAssociati(false);
              }}
            >
              {associati.Title}
              <img className="show-mobile" src={arrow} alt="arrow" />
              <WhiteBorderTop />
            </Text>
          ))}
        </div>

        <div
          className="associati-bio-text"
          id="associati-bio-text"
          ref={refBio}
          style={{
            opacity: transitionOpacity,
            transition: "opacity 1.5s ease",
            visibility: noAssociati ? "hidden" : "visible",
            paddingBottom: "150px",
            paddingTop: "0px",
          }}
        >
          <Text
            className="associati-bio-title"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: 'smooth'
              });
              setTransitionOpacity(0);
              setNoAssociati(true);
            }}
          >
            {currentAssociati?.Title}
            <img
              className="show-mobile"
              src={arrow}
              alt="arrow"
              style={{ display: "none" }}
            />
          </Text>
          <div style={{ position: "relative" }}>
            <Text
              style={{ lineHeight: "22px" }}
              dangerouslySetInnerHTML={{
                __html: currentAssociati?.Description,
              }}
            ></Text>
            {/* <WhiteBorderTop /> */}
          </div>
          {currentAssociati?.Curriculum_Vitae_PDF.length > 0 && (
            <ListText className="associati-name associati-contact">
              <a
                target="_blank"
                href={currentAssociati?.Curriculum_Vitae_PDF[0]?.url || "#"}
                style={{ textDecoration: "none", color: "black" }}
                rel="noopener noreferrer"
              >
                Curriculum Vitae PDF
              </a>
              <img
                className="show-mobile-address"
                src={external}
                alt="external url"
              />
              {/* <WhiteBorderTop /> */}
            </ListText>
          )}
          {currentAssociati?.Email_Address && (
            <ListText className="associati-name associati-contact">
              {currentAssociati?.Email_Address && (
                <a
                  target="_blank"
                  href={currentAssociati?.Email_Address_URL || "#"}
                  style={{ textDecoration: "none", color: "black" }}
                  rel="noopener noreferrer"
                >
                  {currentAssociati?.Email_Address}
                </a>
              )}
              <img
                className="show-mobile-address"
                src={external}
                alt="external url"
              />
              {/* <WhiteBorderTop /> */}
            </ListText>
          )}
          {currentAssociati?.PhoneNumber && (
            <ListText className="associati-name associati-contact">
              {currentAssociati?.PhoneNumber && (
                <a
                  target="_blank"
                  href={currentAssociati?.PhoneNumber_URL || "#"}
                  style={{ textDecoration: "none", color: "black" }}
                  rel="noopener noreferrer"
                >
                  {currentAssociati?.PhoneNumber}
                </a>
              )}
              <img
                className="show-mobile-address"
                src={external}
                alt="external url"
              />
              {/* <WhiteBorderTop /> */}
            </ListText>
          )}
          
          {/* <div style={{ marginTop: "57px" }} /> */}

          <div className="margin-top-white-border" />

          {associatis[0].Associati?.map((associati, index) => (
            <d>
              {associati.Title === currentAssociati?.Title ? (
                ""
              ) : (
                <Text
                  style={{ lineHeight: "22px", cursor: "pointer" }}
                  key={"name " + index}
                  className="associati-name bold-hover"
                  onClick={() => {
                    setCurrentAssociati(associati);
                  }}
                >
                  {associati.Title}
                  <img 
                    className="show-mobile" 
                    src="/static/media/arrow-down.f8cc9c9c.svg" 
                    alt="arrow"
                    style={{ 
                      position: "absolute",
                      right: "-26px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      verticalAlign: "middle"
                    }}
                  />
                  <WhiteBorderTop />
                </Text>
              )}
            </d>
          ))}
          <div
            style={{ height: "calc(200vh - 135px)" }}
            className="bottom-margin-cover"
          >
            <div
              style={{
                position: "absolute",
                marginTop: "20px",
                height: "calc(200vh - 135px)",
                width: "100%",
                left: "-50px",
                background: "#f0f0f0",
              }}
            ></div>
          </div>
        </div>
      </Row>
    </Container>
  );
}
