import styled from "styled-components";

export const BlackOverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 1;
  -webkit-transition: 1s ease-in-out;
  -moz-transition: 1s ease-in-out;
  -o-transition: 1s ease-in-out;
  transition: opacity 3s ease-in-out;
  z-index: 9999;
  pointer-events: none;


  .logo-container {
    position: absolute;
    top: 18.9px;
    left: 50px;
    z-index: 10000;
    display: flex;
    align-items: center;
  }

  &.fadeOut {
    opacity: 0;
  }

  @media (max-width: 750px) {
  .logo-container {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10000;
    display: flex;
    align-items: center;
  }
  }
`; 