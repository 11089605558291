import React from "react";
import { Container, Row } from "react-bootstrap";
import { Text } from "./styles";

export default function Contatti({ about, id }) {
  return (
    <Container id={id} fluid className=" w-md-100 pb-4">
      <Row style={{ marginBottom: "1rem" }}>
        <div className="hack col-mattia"> </div>
        <div className="col-about-tab">
          <Text>
            {about.contact.address}
            <br /> {about.contact.address_complement}
            <br /> {about.contact.Region}
          </Text>
          <Text className="m-0">{about.contact.phone}</Text>

          <a href={`mailto:${about.contact.email}`}>
            <Text>{about.contact.email}</Text>
          </a>
        </div>
      </Row>
    </Container>
  );
}
