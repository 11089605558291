import styled from "styled-components";

export const Container = styled.p`
  width: 100%;
  margin: 0;
  // font-size: inherit;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: inherit;
  margin: 0px 0px 0px 0px;
  border: none;
  hr {
    margin-top: 12px;
    margin-bottom: 2px;
    border-bottom: 1px solid rgba(119, 110, 98, 0.4);
    border-top: none;
  }

  p {
    // font-size: 17px;
    font-weight: 400;
    font-family: "Libre Franklin", sans-serif;
    line-height: 22px;
    color: #000000 !important;
  }
`;

export const Link = styled.a`
  color: #776e62;
  &:hover {
    color: black;
  }
`;
