import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import { Text, TextTimeline } from "./styles";
import HtmlRender from "../../components/HtmlRender";
import external from "./../../assets/img/external.svg";
import WhiteBorderTop from "../WhiteBorderTop";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function Timeline({ timeline, activeTab, setActiveTab, ref }) {
  const mobileScreens = useMediaQuery("(max-width: 768px)");

  const [opacity, setOpacity] = useState(0);

  useEffect(() => {
    setOpacity(1);
  }, []);

  return (
    <Container fluid className="w-md-100 associati-container" ref={ref}>
      {/* <div className="animated-border-container" ref={timelineAnimatedContainerRef} style={{height: `${animatedBorderContainerHeight}%`, transitionDuration:  `${timeline.length > 10 ? timeline.length > 30 ? 2.3 : 1 : 0.5}s`}} /> */}
      <Row className="associati-row" style={{ opacity: opacity }}>
        <Text
          className="mobile-tab-title associati-title"
          onClick={() => setActiveTab(undefined)}
        >
          {activeTab}
          <WhiteBorderTop />
        </Text>

        <div className="hack col-mattia"> </div>
        <div className="timeline-bio-text">
          {timeline.map((el, idx) => (
            <div
              key={"timeline " + idx + el.text}
              style={{ position: 'relative' }}
            >
              {mobileScreens && (
                <img
                  className="external-link-icon"
                  src={external}
                  alt="external url"
                  style={{
                    display: "block",
                    position: "absolute",
                    right: "-37px",
                    top: "24px"
                  }}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "start",
                  justifyContent: "space-between",
                  flex: 12,
                  paddingTop: "13px",
                  paddingBottom: "12px",
                }}
              >
                <div style={{ display: "flex", width: "93px", whiteSpace: "nowrap" }}>
                  <TextTimeline>{el.year}</TextTimeline>
                </div>
                <div
                  style={{ display: "flex", flex: 10 }}
                  className="normalize-bios-text-no-margin"
                >
                  <HtmlRender>{el.text}</HtmlRender>
                </div>
                {!mobileScreens && (
                  <div style={{ width: "20px", display: "flex", justifyContent: "flex-start", opacity: 1, paddingTop: "13px" }}>
                    <img
                      className="external-link-icon"
                      src={external}
                      alt="external url"
                      style={{ display: "block" }}
                    />
                  </div>
                )}
              </div>
              {/* {idx === 0 && <WhiteBorderTop />} */}
            </div>
          ))}
        </div>
      </Row>
    </Container>
  );
}
